import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Grid from '../../components/Grid/Grid';
import './artistDetail.scss';

function ArtistDetail({ data }) {
  const params = useParams();
  const [inventory, setInventory] = useState(null);
  const [artist, setArtist] = useState(null);
  console.log('data', data);

  useEffect(() => {
    const userId = params.id;

    if (data) {
      const artistFromData = data.find((d) => d.id === userId);
      setArtist(artistFromData);

      if (!artistFromData) {
        window.location.href = window.location.origin;
      }

      const inventoryFromData = artistFromData.artwork
        .map((a) => ({
          ...a,
          artist: artistFromData.name,
          photo: artistFromData.photo,
          artistId: artistFromData.id,
        }))
        .flat();
      setInventory(inventoryFromData);
    }
  }, [data, params]);

  if (artist)
    return (
      <div className='artist-detail'>
        <h1>{artist.name}</h1>
        {artist.background ? (
          <img src={artist.background} className='background' />
        ) : (
          <img src={artist.photo} className='photo' />
        )}
        <div className='meta'>
          <label>
            {artist.style} artist in {artist.location}
          </label>

          <div>{artist.bio}</div>
        </div>
        <h2>Artwork</h2>
        <Grid items={inventory} />
      </div>
    );
}

export default ArtistDetail;
