import React from 'react';
import './contact.scss';

function Contact() {
  return (
    <div className='pages about'>
      <h1>Contact</h1>
      <div>
        <label>Email</label>
        <p>
          <a href='mailto:hello@collectors-table.com'>
            hello@collectors-table.com
          </a>
        </p>
      </div>
      <div>
        <label>Text Message</label>
        <p>
          <a href='tel:+18054099506'>(805) 409-9506</a>
        </p>
      </div>
      <div>
        <label>Call</label>
        <p>
          <a href='sms:+18054099506?&body=Hello,"'>(805) 409-9506</a>
        </p>
      </div>
    </div>
  );
}

export default Contact;
