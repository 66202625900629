import React, { useEffect, useState } from 'react';
import Input from '../../components/Input/Input';
import { CountriesArray, printedOnArray } from '../../helpers/helpers';
import Select from '../../components/Select/Select';
import { formatter } from '../../helpers/helpers';

const inputfields = [
  {
    type: 'email',
    id: 'email',
    auto: 'email',
  },
  {
    type: 'text',
    id: 'cellphone',
    auto: 'tel',
  },
  {
    type: 'text',
    id: 'name',
    auto: 'name',
  },
  {
    type: 'text',
    id: 'street',
    auto: 'street-address',
  },
  {
    type: 'text',
    id: 'city',
    auto: 'address-level2',
    className: 'first half',
  },
  {
    type: 'text',
    id: 'state',
    auto: 'state',
    className: 'half',
  },
  {
    type: 'text',
    id: 'zip',
    auto: 'postal-code',
    className: 'first half',
  },
  /*   {
    type: 'text',
    id: 'country',
    auto: 'country-name',
    className: 'half'
  } */
];

const printChoices = printedOnArray.map(p => p.type);

//1FAIpQLSdr0_dMMvWsVCY2mVmFl99XKauWok1ghyBskRe9FB4yMek8Dg

function ArtworkBuySection({ artwork, artist }) {
  const [email, setEmail] = useState('');
  const [cellphone, setCellphone] = useState('');
  const [name, setName] = useState('');
  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [country, setCountry] = useState('United States');
  const [finalPrice, setFinalPrice] = useState(artwork.price);
  const [enabledPayment, setEnabledPayment] = useState(false);
  const [printedOn, setPrintedOn] = useState(printChoices[0])

  const handleSubmitForm = () => {
    setTimeout(() => {
      window.document.querySelector('#submitBuyForm').click();
      const link = `http://www.paypal.com/cgi-bin/webscr?&cmd=_xclick&business=${artwork.type.includes('riginal') ? 'hello@collectors-table.com' : artist.paypal}&currency_code=USD&amount=${finalPrice}&item_name=${artwork.id}&return=http://collectors-table.com/hook?productId=${artwork.id}&productName=${artwork.title}&totalPrice=${finalPrice}&productPrice=${artwork.price}&artistName=${artist.name}&prouctPhoto=${artwork.images[0]}&buyerCountry=${country}&buyerEmail=${email}`;

      console.log('link', link);

      window.location.href = link;
    }, 500);
  };

  const isFormComplete = () => {
    if (
      email.length > 0 &&
      cellphone.length > 0 &&
      name.length > 0 &&
      street.length > 0 &&
      city.length > 0 &&
      state.length > 0
    ) {
      getPrice(country);
      setEnabledPayment(true);
    } else {
      getPrice(country);
      setEnabledPayment(false);
    }
  };

  const handleValue = (value, id) => {
    if (id === 'email') setEmail(value);
    if (id === 'cellphone') setCellphone(value);
    if (id === 'name') setName(value);
    if (id === 'street') setStreet(value);
    if (id === 'city') setCity(value);
    if (id === 'state') setState(value);
    if (id === 'zip') setZip(value);
    isFormComplete();
  };

  const handleSelect = (value) => {
    //setSelectedCountry(value);
    setCountry(value);
    getPrice(value);
  };

  const handlePrintedOnSelect = (value) => {
    //setSelectedCountry(value);
    setPrintedOn(value);
    getPrice(country, value);
  };

  const handleArtistPayment = () => {
    handleSubmitForm();
  };

  const getPrice = (selectedCountry, selectedPrintedOn) => {
    let finalCharge;
    let artworkPrice;

    if (artwork.type.includes('riginal')) {
      artworkPrice = artwork.price;
    } else {
      const pOn = selectedPrintedOn || printedOn
      const selected = printedOnArray.find(p => p.type === pOn);

      artworkPrice = selected.price;
    }

    if (selectedCountry.includes('United States')) {
      finalCharge = artworkPrice;
      setFinalPrice(finalCharge);
    } else {
      if (artwork.type.includes('riginal')) {
        finalCharge = artworkPrice + 200;
        setFinalPrice(finalCharge);
      } else {
        finalCharge = artworkPrice + 50;
        setFinalPrice(finalCharge);
      }
    }
  };

  useEffect(() => {
    getPrice(country);
  }, [artwork]);

  return (
    <div className='buy-section'>
      {!artwork.type.includes('riginal') && <><h5>Printed On</h5><Select
        items={printChoices}
        handleSelect={handlePrintedOnSelect}
        //selectedItem={selectedCountry}
        selectedItem={printedOn}
      /></>}
      {/* {`http://www.paypal.com/cgi-bin/webscr?&cmd=_xclick&business=${artist.paypal}&currency_code=USD&amount=${finalPrice}&item_name=${artwork.id}&return=http://collectors-table.com/hook?productId=${artwork.id}&productName=${artwork.title}&totalPrice=${finalPrice}&productPrice=${artwork.price}&artistName=${artist.name}&prouctPhoto=${artwork.images[0]}&buyerCountry=${country}&buyerEmail=${email}&artworkType=${artwork.type}`} */}
      <h5>Shipping Info {country}</h5>
      {/* <div>email: {email}</div>
      <div>cellphone: {cellphone} </div>
      <div>name: {name}</div>
      <div>street: {street} </div>
      <div>city: {city}</div>
      <div>state: {state}</div>
      <div>zip: {zip}</div>
      <div>country: {country}</div> */}

      {inputfields.map((f) => (
        <div key={f.id} className={f.className ? f.className : ''}>
          <Input
            id={f.id}
            handleValue={handleValue}
            auto={f.auto}
            type={f.type}
          />
        </div>
      ))}
      <div className='half'>
        <Select
          items={CountriesArray}
          handleSelect={handleSelect}
          //selectedItem={selectedCountry}
          selectedItem={country}
        />
      </div>
      <div style={{ clear: 'both', marginBottom: '5px' }} />
      {enabledPayment ? (
        <div class='btn' onClick={() => handleArtistPayment()}>
          Pay {formatter(finalPrice)} via{' '}
          <img src='/PayPal.png' alt='paypal' className='paypal' />
        </div>
      ) : (
        <div class='btn disabled'>
          Complete form above to pay via{' '}
          <img src='/PayPal.png' alt='paypal' className='paypal' />
        </div>
      )}

      <iframe
        title='secret-frame-one'
        name='secret-frame-one'
        width='0'
        height='0'
        border='0'
        style={{ display: 'none' }}
      ></iframe>
      <form
        className='form'
        action='https://docs.google.com/forms/d/e/1FAIpQLSdr0_dMMvWsVCY2mVmFl99XKauWok1ghyBskRe9FB4yMek8Dg/formResponse'
        id='ss-form1'
        method='POST'
        target='secret-frame-one'
        style={{ display: 'none' }}
      >
        <div className='form-group'>
          <label htmlFor='itemId'>Item Id</label>
          <input
            name='entry.1934829020'
            readOnly
            type='text'
            value={artwork.id}
          />
        </div>
        <div className='form-group'>
          <label htmlFor='price'>Item Price</label>
          <input
            name='entry.1857223755'
            readOnly
            type='text'
            value={finalPrice}
          />
        </div>
        <div className='form-group'>
          <label htmlFor='street'>Street Address</label>
          <input name='entry.920808194' readOnly type='text' value={street} />
        </div>
        <div className='form-group'>
          <label htmlFor='street'>Street Address</label>
          <input name='entry.110163301' readOnly type='text' value={city} />
        </div>
        <div className='form-group'>
          <label htmlFor='state'>State</label>
          <input name='entry.1152817679' readOnly type='text' value={state} />
        </div>
        <div className='form-group'>
          <label htmlFor='country'>Country</label>
          <input name='entry.1164855261' readOnly type='text' value={country} />
        </div>
        <div className='form-group'>
          <label htmlFor='email'>Email</label>
          <input name='entry.1677672004' readOnly type='text' value={email} />
        </div>
        <div className='form-group'>
          <label htmlFor='phone'>Cellphone</label>
          <input name='entry.34772403' readOnly type='text' value={cellphone} />
        </div>

        <input id='submitBuyForm' type='submit' value='Submit Buy' />
      </form>
    </div>
  );
}

export default ArtworkBuySection;
