import React, { useState } from 'react';
import './subscribe.scss';

function Subscribe({handleToast}) {
  const [email, setEmail] = useState('');
  const [buttonState, setButtonState] = useState('Subscribe');

  const handleSubmitForm = () => {
    setButtonState('Sending ...');
    handleToast({icon: 'success', text: 'You\'ve been added to our mailing list.'})
    setTimeout(() => {
      window.document.querySelector('#submitEmailForm').click();
    }, 500);

    setTimeout(() => {
      setButtonState('Subscribe');
      setEmail('')
    }, 1500);
  };

  return (
    <div className='pages subscribe'>
      <h1>Subscribe</h1>
      <p>
        Be first in line to know about new art posted for sale and be part of
        our invite only sales.
      </p>

      <input
        onChange={(e) => setEmail(e.target.value)}
        value={email}
        placeholder='Enter your Email'
        type='email'
      />
      <button onClick={() => handleSubmitForm()}>{buttonState}</button>

      <iframe
        title='secret-frame-four'
        name='secret-frame-four'
        width='0'
        height='0'
        border='0'
        style={{ display: 'none' }}
      ></iframe>
      <form
        className='form'
        action='https://docs.google.com/forms/d/e/1FAIpQLSe7qYz7lsqDNv9EEjFAr8gRtcjxtOFPO_pyGE-nUisEowfN9Q/formResponse'
        id='ss-form4'
        method='POST'
        target='secret-frame-four'
        style={{ display: 'none' }}
      >
        <div className='form-group'>
          <label htmlFor='subscriptionEmail'>Email</label>
          <input name='entry.1298851345' readOnly type='email' value={email} />
        </div>

        <input id='submitEmailForm' type='submit' value='Submit Subscribe' />
      </form>
    </div>
  );
}

export default Subscribe;
