import React, { useEffect, useState, useMemo } from 'react';
import Grid from '../../components/Grid/Grid';

function Prints({ data }) {
  const [inventory, setInventory] = useState(null);

  const filterData = (data) => {
    const inventoryFromData = data
      .map((d) =>
        d.artwork.map((dd) => ({
          ...dd,
          artist: d.name,
          photo: d.photo,
          artistId: d.id,
        }))
      )
      .flat()
      .filter((ff) => ff.type.includes('Print'));
    setInventory(inventoryFromData);
  };

  useMemo(() => filterData(data), [data]);

  if (inventory)
    return (
      <div className='home'>
        <h1 className='center'>
          Curated Art: Prints
        </h1>
        {inventory && <Grid items={inventory} />}
      </div>
    );
  return 'No results';
}

export default Prints;
