import React from 'react';
import { Link } from 'react-router-dom';
import './about.scss';

function About() {
  return (
    <div className='pages about'>
      <h1>About</h1>
      <h2>
        <label>What We Do</label>
      </h2>
      <p>
        Collectors Table is an online gallery of curated work from new and
        contemporary artists. The work for sale range from prints to original
        artwork.
      </p>
      <p>
        We scour the ends of the world to find art that speaks to us and provide
        this platform so you can discover art that speaks to you.
      </p>
      <h2>
        <label>Contact Us</label>
      </h2>
      <p>
        Please send an email to{' '}
        <a href='mailto:hello@collectors-table.com'>
          hello@collectors-table.com
        </a>
      </p>
      <h2>
        <label>Submit Artwork</label>
      </h2>
      <p>
        Details on submitting artwork for consideration are available{' '}
        <Link to='/submit'>here.</Link>
      </p>
      <h2>
        <label>Join Our Mailing Lists</label>
      </h2>
      <p>
        Be first on the list by clicking <Link to='/subscribe'>here.</Link>
      </p>
      <h2>
        <label>Our Founders</label>
      </h2>
      <p>
        Enrique Florendo is a designer, software engineer, and lover of art.
      </p>
      <p>
        Marisa Oge is an artist, holds a doctorate of science from Harvard, and
        currently works as a behavior designer melding her artistic and
        scientific minds.
      </p>
      <p>Collectors Table is a subsidiary of Hey Hey Gorgeous, LLC.</p>
    </div>
  );
}

export default About;
