import React from 'react';

function ArtworkOriginalSection() {
  return (
    <div>
      <p>
        Original art is bought directly from the artist via Paypal. We will
        retain a copy of the sale details. Shipping within the United States,
        handling, and insurance is included in the listing price.
      </p>
      <p>
        There is an extra $200 charge for shipping outside the United States.
        The price will reflect this cost when you fill out the form below.
      </p>
      <p>Please contact us if you have any issues.</p>
    </div>
  );
}

export default ArtworkOriginalSection;
