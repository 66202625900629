import React, { useState } from 'react';
import Input from '../../components/Input/Input';
import './login.scss';

function Login() {
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);

    const handleValue = (value) => {
        setPassword(value);
    }
    const submitLoginForm = () => {
        if (password === 'TableForMany') {
            localStorage.setItem('loggedIn', 'true');
            window.location.href = window.location.origin;
        } else {
            if (!error) {
                setError('Incorrect Password.')
                setTimeout(() => {
                    setError(null)
                }, 3000);
            }

        }
    }
    return (
        <div className='login'>
            <h1><label>Login</label></h1>
            <Input id="Enter password" handleValue={handleValue} />
            <button onClick={() => submitLoginForm()}>Submit</button>
            {error && <h3>{error}</h3>}
        </div>
    )
}

export default Login