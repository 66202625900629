import React, { useState, useMemo } from 'react';
import Grid from '../../components/Grid/Grid';
import './home.scss';

function Home({ data }) {
  const [inventory, setInventory] = useState(null);

  const processData = (data) => {
    const inventoryFromData = data
      .map((d) =>
        d.artwork.map((dd) => ({
          ...dd,
          artist: d.name,
          photo: d.photo,
          artistId: d.id,
        }))
      )
      .flat();
    setInventory(inventoryFromData);
  };

  useMemo(() => processData(data), [data]);

  if (inventory)
    return (
      <div className='home'>
        <h1 className='center'>
          Curated Art: Original and Prints
        </h1>
        {inventory && <Grid items={inventory} />}
      </div>
    );
  return 'No results';
}

export default Home;
