import React from 'react'

function Logout() {
    localStorage.clear();
    window.location.href = window.location.origin;
  return (
    <div>Logout</div>
  )
}

export default Logout