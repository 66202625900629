import React, { useEffect, useState } from 'react';
import { formatter } from '../../helpers/helpers';
//import { useParams } from 'react-router-dom';
import './hook.scss';

function Hook() {
  //const params = useParams();
  const search = window.location.search.substring(1);
  const paramsObject = JSON.parse(
    '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
    function (key, value) {
      return key === '' ? value : decodeURIComponent(value);
    }
  );
  
  const [productId, setProductId] = useState('');
  const [productName, setProductName] = useState('');
  const [totalPrice, setTotalPrice] = useState('');
  const [productPrice, setProductPrice] = useState('');
  const [buyerCountry, setBuyerCountry] = useState('');
  const [buyerEmail, setBuyerEmail] = useState('');
  const [artistName, setArtistName] = useState('');

  useEffect(() => {
    setProductId(paramsObject.productId);
    setProductName(paramsObject.productName);
    setTotalPrice(paramsObject.totalPrice);
    setProductPrice(paramsObject.productPrice);
    setBuyerCountry(paramsObject.buyerCountry);
    setBuyerEmail(paramsObject.buyerEmail);
    setArtistName(paramsObject.artistName);

    setTimeout(() => {
      //post to form
      window.document.querySelector('#submitHookForm').click();
    }, 500);
  }, []);

  return (
    <div className='hook'>
      <h1>Thank you for your purchase.</h1>
      {paramsObject.artworkType === 'Original' ? (
        <div className='info'>
          We are processing the order and will get back to you via email with an
          estimated ship date. Please contact{' '}
          <a href='mailto:hello@collectors-table.com'>
            hello@collectors-table.com
          </a>{' '}
          if you have any questions. Congratulations on your original artwork
          purchase!
        </div>
      ) : (
        <div className='info'>
          We are processing the order and will get back to you via email with an
          estimated ship date. Please contact{' '}
          <a href='mailto:hello@collectors-table.com'>
            hello@collectors-table.com
          </a>{' '}
          if you have any questions. We appreciate your support and hope to
          deliver inspiration in your life!
        </div>
      )}
      <table>
        <tr>
          <td className='first'>
            <label>Artwork</label>
          </td>
          <td>
            {paramsObject.productName} by <em>{paramsObject.artistName}</em>
          </td>
        </tr>
        <tr>
          <td className='first'>
            <label>Price</label>
          </td>
          <td>{formatter(paramsObject.productPrice)}</td>
        </tr>
        <tr>
          <td className='first'>
            <label>Price + S/H</label>
          </td>
          <td>{formatter(paramsObject.totalPrice)}</td>
        </tr>
        <tr>
          <td className='first'>
            <label>Buyer</label>
          </td>
          <td>{paramsObject.buyerEmail}</td>
        </tr>
      </table>
      <img src={paramsObject.prouctPhoto} alt={paramsObject.productName} />
      <iframe
        title='secret-frame-two'
        name='secret-frame-two'
        width='0'
        height='0'
        border='0'
        style={{ display: 'none' }}
      ></iframe>
      <form
        className='form'
        action='https://docs.google.com/forms/d/e/1FAIpQLSdW385aIUB_AV2P7gnT9SBtgs1wwqx1RLGbnpxPBwSYSFZ8Vw/formResponse'
        id='ss-form4'
        method='POST'
        target='secret-frame-two'
        style={{ display: 'none' }}
      >
        <div className='form-group'>
          <label htmlFor='productId'>productId</label>
          <input
            name='entry.815049177'
            readOnly
            type='text'
            value={productId}
          />
        </div>

        <div className='form-group'>
          <label htmlFor='productName'>productName</label>
          <input
            name='entry.166782121'
            readOnly
            type='text'
            value={productName}
          />
        </div>

        <div className='form-group'>
          <label htmlFor='totalPrice'>totalPrice</label>
          <input
            name='entry.1419830493'
            readOnly
            type='text'
            value={totalPrice}
          />
        </div>

        <div className='form-group'>
          <label htmlFor='productPrice'>productPrice</label>
          <input
            name='entry.1897574747'
            readOnly
            type='text'
            value={productPrice}
          />
        </div>

        <div className='form-group'>
          <label htmlFor='buyerCountry'>buyerCountry</label>
          <input
            name='entry.2136697772'
            readOnly
            type='text'
            value={buyerCountry}
          />
        </div>

        <div className='form-group'>
          <label htmlFor='buyerEmail'>buyerEmail</label>
          <input
            name='entry.1397257146'
            readOnly
            type='text'
            value={buyerEmail}
          />
        </div>

        <div className='form-group'>
          <label htmlFor='artistName'>artistName</label>
          <input
            name='entry.1735117951'
            readOnly
            type='text'
            value={artistName}
          />
        </div>
        <input id='submitHookForm' type='submit' value='Submit Paypal Hook' />
      </form>
    </div>
  );
}

export default Hook;
