import React, {useState} from 'react'
import './Input.scss';

function Input({id, auto, type, handleValue}) {

  return (
    <div className='input'><input placeholder={id} autocomplete="on" id={auto} name={auto} type={type} onChange={(e) => handleValue(e.target.value, id)} /></div>
  )
}

export default Input