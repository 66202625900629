import React from 'react';
import { Link } from 'react-router-dom';
import './Menu.scss';

function Menu({ handleMenu }) {
  return (
    <div className='menu'>
      <img
        src='/Collectors-Table-Logo.svg'
        className='hidden-desktop'
        alt='Collectors Table'
      />
      <menu className='dropdown'>
        <li>
          <Link onClick={() => handleMenu()} to='/'>
            View Art
          </Link>
        </li>
        <li>
          <Link onClick={() => handleMenu()} to='/about'>
            About
          </Link>
        </li>
        <li onClick={() => handleMenu()}>
          <Link onClick={() => handleMenu()} to='/submit'>
            Submit Artwork
          </Link>
        </li>
        <li onClick={() => handleMenu()}>
          <Link onClick={() => handleMenu()} to='/contact'>
            Contact
          </Link>
        </li>
      </menu>
    </div>
  );
}

export default Menu;
