import React, {useState} from 'react';
import './Select.scss'

function Select({ items, selectedItem, handleSelect }) {
    const [toggled, setToggled] = useState(false);

    const handleToggle = () => {
        setToggled(!toggled);
    }

    const handleSelectItem = (item) => {
        handleSelect(item);
        setToggled(!toggled)
    }
    return (
        <div className='select'>
            <div className='select-box' onClick={() => handleToggle()}>{selectedItem} <img src='/chevron-down.svg' className='icon' /></div>

            {toggled && <ul>{items.map(item => <li onClick={() => handleSelectItem(item)}>{item}</li>)}</ul>}
            
        </div>

    )
}

export default Select