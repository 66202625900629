import React from 'react';
import Staging from '../../components/Staging/Staging';
import { Link } from 'react-router-dom';
import './landing.scss';

function Landing({ data }) {
  const stagingArtwork = data[0].artwork[0];
  return (
    <div className='pages landing'>
      <Staging artwork={stagingArtwork} />
      <div className='content'>
        <h1>Welcome to Collectors Table</h1>
        <h3>
          Collectors Table is an online gallery that sells original artwork,
          prints, and art commissions from a curated list of artists.
        </h3>
        <div className='actions'>
          <div>
            <label>Gallery Opening on December, 2022</label>
          </div>
          <Link to='/subscribe'>Join the Mailing List</Link>
          <Link to='/submit'>Submit Artwork</Link>
        </div>
      </div>
    </div>
  );
}

export default Landing;
