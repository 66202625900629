import React, { useEffect, useState } from 'react';
import './Staging.scss';

const rooms = [
  {
    title: 'Living Room',
    image: '/LivingRoomWall2.jpeg',
    position: 'center',
    height: '42.5vh',
    top: '3vh',
    right: 0,
    left: '0',
  },
  {
    title: 'Living Room',
    image: '/lr2.jpg',
    height: '40vh',
    top: '3vh',
    right: 0,
    left: '25vw',
  },
  {
    title: 'Living Room',
    image: '/lr.jpg',
    height: '37vh',
    top: '5vh',
    right: 0,
    left: '25vw',
  },
  {
    title: 'Living Room',
    image: '/lr3.jpg',
    height: '40.5vh',
    top: '2vh',
    right: 0,
    left: 0,
  },
  {
    title: 'Entry',
    image: '/white-bathroom.jpeg',
    height: '42vh',
    top: '4vh',
    right: 0,
    left: '25vw',
  },
];

function Staging({ artwork }) {
  const [selectedRoomIndex, setSelectedRoomIndex] = useState(0);

  rooms.forEach((r) => {
    const img = new Image();
    img.src = r.image;
  });

  const moveForward = () => {
    if (selectedRoomIndex < rooms.length - 1) {
      setSelectedRoomIndex(selectedRoomIndex + 1);
    } else {
      setSelectedRoomIndex(0);
    }
  };

  const moveBackward = () => {
    if (selectedRoomIndex > 0) {
      setSelectedRoomIndex(selectedRoomIndex - 1);
    } else {
      setSelectedRoomIndex(rooms.length - 1);
    }
  };

  return (
    <div
      className='staging'
      style={{
        backgroundImage: `url(${rooms[selectedRoomIndex].image})`,
        backgroundPosition: rooms[selectedRoomIndex].position,
      }}
    >
      {' '}
      <div className='caveat'>Artwork/Background size is not 1:1 ratio</div>
      <div className='controls'>
        <img
          src='/chevron-back-sharp.svg'
          className='icon'
          onClick={() => moveBackward()}
        />
        <img
          src='/chevron-forward-sharp.svg'
          className='icon'
          onClick={() => moveForward()}
        />
      </div>
      <img
        className={`artwork-image ${
          artwork.width < artwork.height ? 'vertical' : ''
        }`}
        style={{
          height: rooms[selectedRoomIndex].height,
          top: rooms[selectedRoomIndex].top,
          right: rooms[selectedRoomIndex].right,
          left: rooms[selectedRoomIndex].left,
          maxHeight: rooms[selectedRoomIndex].maxHeight,
        }}
        src={artwork.images[0]}
        alt={`Image of ${artwork.title} ${rooms[selectedRoomIndex].title}`}
      />
    </div>
  );
}

export default Staging;
