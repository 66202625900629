import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import About from './pages/about/about';
import Submit from './pages/submit/submit';
import Home from './pages/home/home';
import Prints from './pages/prints/prints';
import Originals from './pages/originals/originals';
import Artists from './pages/artists/artists';
import ArtistDetail from './pages/artistDetail/artistDetail';
import Contact from './pages/contact/contact';
import ArtWorkDetail from './pages/artworkDetail/artworkDetail';
import Subscribe from './pages/subscribe/subscribe';
import Landing from './pages/landing/landing';
import Login from './pages/login/login';
import Logout from './pages/logout/logout';
import Hook from './pages/hook/hook';
import { getData, getDataLanding } from './helpers/api';
import Loader from './layout/Loader/Loader';
import Nav from './layout/Nav/Nav';
import LandingNav from './layout/Nav/LandingNav';
import './App.scss';

const App = () => {
  const [data, setData] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showToast, setShowToast] = useState(null);

  const isAuthed = () => {
    const authed = localStorage.getItem('loggedIn');
    if (authed) {
      setIsLoggedIn(true);
      getDataFromApi();
    } else {
      setIsLoggedIn(false);
      getDataFromApiLanding();
    }
  };

  const getDataFromApi = async () => {
    const dataFromApi = await getData();
    setData(dataFromApi);
  };

  const getDataFromApiLanding = async () => {
    const dataFromApi = await getDataLanding();
    setData(dataFromApi);
  };

  const handleToast = (icon, text) => {
    setShowToast({ icon, text });
    setTimeout(() => {
      setShowToast(null);
    }, 3000);
  };

  useEffect(() => {
    isAuthed();
  }, []);

  if (data)
    return (
      <div className='app'>
        {isLoggedIn && (
          <>
            <Nav />
            <Routes>
              <Route exact path='/' element={<Home data={data} />} />
              <Route path='/prints' element={<Prints data={data} />} />
              <Route path='/originals' element={<Originals data={data} />} />
              <Route path='/artists' element={<Artists data={data} />} />
              <Route path='/about' element={<About />} />
              <Route path='/submit' element={<Submit />} />
              <Route path='/hook' element={<Hook />} />
              <Route
                path='/subscribe'
                element={<Subscribe handleToast={handleToast} />}
              />
              <Route path='/contact' element={<Contact />} />
              <Route path='/landing' element={<Landing />} />
              <Route path='/logout' element={<Logout />} />
              <Route
                path='/artist/:id'
                element={<ArtistDetail data={data} />}
              />
              <Route
                path='/:artist/:id'
                element={<ArtWorkDetail data={data} />}
              />
              <Route path='*' element={<Navigate to='/' replace={true} />} />
            </Routes>
          </>
        )}
        {!isLoggedIn && (
          <>
            <LandingNav />
            <Routes>
              <Route path='/about' element={<About />} />
              <Route
                path='/subscribe'
                element={<Subscribe handleToast={handleToast} />}
              />
              <Route path='/submit' element={<Submit />} />
              <Route path='/' element={<Landing data={data} />} />
              <Route path='/contact' element={<Contact />} />
              <Route path='/login' element={<Login />} />
              <Route
                path='/artist/:id'
                element={<ArtistDetail data={data} />}
              />
              <Route
                path='/:artist/:id'
                element={<ArtWorkDetail data={data} />}
              />
              <Route path='*' element={<Navigate to='/' replace={true} />} />
            </Routes>
          </>
        )}
      </div>
    );
  return <Loader />;
};

export default App;
