import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Grid from '../../components/Grid/Grid';
import Staging from '../../components/Staging/Staging';
import ArtworkBuySection from './artworkBuySection';
import ArtworkPrintSection from './artworkPrintSection';
import ArtworkOriginalSection from './artworkOriginalSection';
import './artworkDetail.scss';

const rooms = [
  {
    title: 'Living Room',
    image: '/LivingRoomWall2.jpeg',
    position: 'center',
    height: '42.5vh',
    top: '5vh',
    right: 0,
    left: '0',
  },
  {
    title: 'Living Room',
    image: '/lr2.jpg',
    height: '45vh',
    top: '5vh',
    right: 0,
    left: '25vw',
  },
  {
    title: 'Living Room',
    image: '/lr.jpg',
    height: '50vh',
    top: '7.5vh',
    right: 0,
    left: '25vw',
  },
  {
    title: 'Living Room',
    image: '/lr3.jpg',
    height: '45vh',
    top: '5vh',
    right: 0,
    left: 0,
  },
  {
    title: 'Entry',
    image: '/white-bathroom.jpeg',
    height: '40vh',
    top: '5vh',
    right: 0,
    left: '25vw',
  },
];

function ArtworkDetail({ data }) {
  const params = useParams();
  const [artwork, setArtwork] = useState(null);
  const [artist, setArtist] = useState(null);
  const [selectedRoomIndex, setSelectedRoomIndex] = useState(0);
  const [toggledBuy, setToggledBuy] = useState(false);

  const moveForward = () => {
    if (selectedRoomIndex < rooms.length - 1) {
      setSelectedRoomIndex(selectedRoomIndex + 1);
    } else {
      setSelectedRoomIndex(0);
    }
  };

  const moveBackward = () => {
    if (selectedRoomIndex > 0) {
      setSelectedRoomIndex(selectedRoomIndex - 1);
    } else {
      setSelectedRoomIndex(rooms.length - 1);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (data) {
      const artistId = params.artist;
      const artworkId = params.id;

      const artistFromData = data.find((d) => d.id === artistId);
      setArtist(artistFromData);
      const artworkFromData = artistFromData.artwork.find(
        (a) => a.id === artworkId
      );
      setArtwork(artworkFromData);

      if (!artworkFromData) {
        window.location.href = `${window.location.origin}/artist/${artistFromData.id}`;
      }
    }
  }, [data, params]);

  if (artwork) {
    return (
      <div className='artwork-detail'>
        {/* <div
          className='room-staging'
          style={{
            backgroundImage: `url(${rooms[selectedRoomIndex].image})`,
            backgroundPosition: rooms[selectedRoomIndex].position,
          }}
        >
          <div className='controls'>
            <img
              src='/chevron-back-sharp.svg'
              class='icon'
              onClick={() => moveBackward()}
            />
            <img
              src='/chevron-forward-sharp.svg'
              class='icon'
              onClick={() => moveForward()}
            />
          </div>
          <img
            className='artwork-image'
            style={{
              height: rooms[selectedRoomIndex].height,
              top: rooms[selectedRoomIndex].top,
              right: rooms[selectedRoomIndex].right,
              left: rooms[selectedRoomIndex].left,
              maxHeight: rooms[selectedRoomIndex].maxHeight,
            }}
            src={artwork.images[0]}
            alt={`Image of ${artwork.title} ${rooms[selectedRoomIndex].title}`}
          />
        </div> */}
        <Staging artwork={artwork} />

        <div className='content'>
          <div className='artwork-meta'>
            <div className={`left ${toggledBuy ? 'full' : ''}`}>
              <h1>{artwork.priceDisplay}</h1>
              <h2>{artwork.medium}</h2>
              <p>
                {artwork.sizes
                  ? artwork.sizes
                  : `${artwork.width}w by ${artwork.height}h inches`}
                , {artwork.type}
              </p>
              <h3
                className={toggledBuy ? 'label' : 'btn'}
                onClick={() => setToggledBuy(true)}
              >
                Buy Artwork
              </h3>
              {toggledBuy && artwork.type.includes('rint') && (
                <ArtworkPrintSection />
              )}

              {toggledBuy && !artwork.type.includes('rint') && (
                <ArtworkOriginalSection />
              )}

              {toggledBuy && (
                <ArtworkBuySection artwork={artwork} artist={artist} />
              )}
            </div>
            <div className='right'>
              <h1>{artwork.title}</h1>
              <label>
                By <Link to={`/artist/${artist.name}`}>{artist.name}</Link>
              </label>
              <p>{artwork.description}</p>
              <p>Style: {artwork.style}</p>
            </div>
          </div>
          <div className='image-container'>
            {artwork.images.map((a) => (
              <img
                src={a}
                alt={`Image of ${artwork.title} Main`}
                className='main-image'
              />
            ))}
          </div>
        </div>
        <div className='suggestions'>
          <h4>More work by {artist.name}</h4>
          <Grid
            items={artist.artwork
              .filter((a) => a.id !== params.id)
              .map((a) => ({
                ...a,
                artist: artist.name,
                artistId: artist.id,
              }))}
          />
        </div>
      </div>
    );
  }
}

export default ArtworkDetail;
