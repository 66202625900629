import React from 'react';
import { Link } from 'react-router-dom';

import './Nav.scss';

function LandingNav() {
  return (
    <nav>
      <div className='logo-menu'>
        <Link to='/'>
          <img src='/Collectors-Table-Logo.svg' alt='Collectors Table' />
        </Link>
      </div>
      <menu>
        <li>
          <Link to='/'>Home</Link>
        </li>
        <li>
          <Link to='/about'>About</Link>
        </li>
        <li>
          <Link to='/submit'>
            Submit<span className='hidden-mobile'> Artwork</span>
          </Link>
        </li>
      </menu>
    </nav>
  );
}

export default LandingNav;
