import React, { useState } from 'react';
import Menu from '../Menu/Menu';
import { Link } from 'react-router-dom';

import './Nav.scss';

function Nav() {
  const [toggledMenu, setToggledMenu] = useState(false);

  const handleMenu = () => {
    setToggledMenu(!toggledMenu);
  };

  return (
    <>
      <nav>
        <div className='logo-menu' onClick={() => setToggledMenu(!toggledMenu)}>
          <img src='/menu-sharp.svg' className='icon' />
          <img
            src='/Collectors-Table-Logo.svg'
            className='hidden-mobile'
            alt='Collectors Table'
          />
        </div>
        <menu onClick={() => setToggledMenu(false)}>
          <li>
            <Link to='/'>All</Link>
          </li>
          <li>
            <Link to='/prints'>Prints</Link>
          </li>
          <li>
            <Link to='/originals'>Originals</Link>
          </li>
          <li>
            <Link to='/artists'>Artists</Link>
          </li>
        </menu>
      </nav>
      {toggledMenu && <Menu handleMenu={handleMenu} />}
    </>
  );
}

export default Nav;
