import axios from 'axios';

const url = '/data/data.json';
const urlLanding = '/data/data-landing.json';

export const getData = async () => {
  const res = await axios.get(url);
  return res.data;
};

export const getDataLanding = async () => {
  const res = await axios.get(urlLanding);
  return res.data;
};
