import React from 'react';
import { Link } from 'react-router-dom';
import './artists.scss';

function Artists({ data }) {
  return (
    <div className='artists'>
      <h1 className='center'>Artists at Collectors Table</h1>
      {data
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((d) => (
          <div className='section'>
            <div className='artist-info'>
              <Link to={`/artist/${d.id}`}>
                <img src={d.photo} />
                <div>
                  <h2>{d.name}</h2>
                  <p>{d.style}</p>
                  <p>{d.location}</p>
                </div>
              </Link>
            </div>
            <div className='artist-gallery'>
              {d.artwork.map((dd) => (
                <Link to={`/${d.id}/${dd.id}`}>
                  <img
                    src={dd.images[0]}
                    className={`artwork-image ${dd.orientation}`}
                  />
                </Link>
              ))}
            </div>
          </div>
        ))}
    </div>
  );
}

export default Artists;
