import React from 'react';
import { Link } from 'react-router-dom';
import './Grid.scss';

function Grid({ items }) {
  return (
    <div className='grid'>
      {items.map((item) => (
        <div key={item.id} className='card'>
          <Link to={`/${item.artistId}/${item.id}`}>
            <div className='card-image'>
              <img src={item.images[0]} alt={`${item.title}`} />
            </div>
          </Link>
          <div className='meta-art'>
            <Link to={`/${item.artistId}/${item.id}`}>
              <h2 className='stylized'>{item.title}</h2>
            </Link>
            <h2>{item.priceDisplay}</h2>
          </div>
          <div className='meta-artist'>
            <h3>
              <div>
                <Link to={`/artist/${item.artistId}`}>
                  <div>{item.artist}</div>
                </Link>

                {item.sizes
                  ? item.sizes
                  : `${item.width}w  ${item.height}h inches`}
              </div>
              <div className='meta-type'>
                <div className='hidden-mobile'>{item.type}</div>
                {item.medium}
              </div>
            </h3>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Grid;
