import React from 'react'

function ArtworkPrintSection() {
  return (
    <div>
      <p>
       Shipping within the United States, handling, and insurance is included in the listed price.
      </p>
      <p>
        There is an extra $50 charge for shipping outside the United States. The price will reflect this cost when you fill out the form below.
      </p>
      <p>Please contact us if you have any issues.</p>
    </div>
  )
}

export default ArtworkPrintSection;