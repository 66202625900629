import React from 'react';
import './submit.scss';

function Submit() {
  return (
    <div className='pages about submit'>
      <h1>Submit Artwork</h1>
      <h2>
        <label>Submission Process</label>
      </h2>
      <p>
        Please send an email to{' '}
        <a href='mailto:hello@collectors-table.com'>
          hello@collectors-table.com
        </a>{' '}
        and we'd be happy to view your work for consideration. There is no fee
        for submissions. We usually respond within 5 business days.
      </p>
      <p>Please include the following:</p>
      <ul>
        <li>Artist Bio</li>
        <li>Artist Location</li>
        <li>
          Photos of your artwork/collection for consideration.
          <div>
            <em className='faded'>
              Please only include artwork ready for sale as prints or originals.
              Images should be JPEG approx. 2000 x 1500 pixels at 72 dpi (no
              larger than 3 mb). RAW and other formats will not be accepted. It
              is good practice to send images that are not high resolution to
              avoid unauthorized printing of artwork. Please label your photos
              as such: lastname_firstname_#.jpg (example Smith_John_02.jpg).
            </em>
          </div>
        </li>
        <li>
          An image list with titles, media/medium, dimensions (height x width x
          depth), date completed, and price (if applicable).
          <div>
            <em className='faded'>
              We are asking for the size of your finished pieces including the
              frame (if applicable) Please list dimensions as height x width x
              depth. Depth is only necessary if your work is three dimensional.
            </em>
          </div>
        </li>
        <li>Social Media or Online Portfolio (if applicable)</li>
      </ul>
    </div>
  );
}

export default Submit;
